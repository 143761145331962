<template>
  <div class="page">
    <Navbar isBack="true" title="充值奖励"/>
    <div class="page_box">
      <div class="vip_level_nav">充值返平台币</div>
      <div class="vip_level_dec">
        玩家通过充值时所支付的真实金额，可以获得相对应百分比的充值金额奖励平台币，对应的百分比奖励如下：
      </div>
      <div class="vip_level_table">
        <div class="vip_level_tr">
          <div class="vip_level_tb">VIP等级</div>
          <div class="vip_level_tb">额外奖励</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip0</div>
          <div class="vip_level_tb">无</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip5</div>
          <div class="vip_level_tb">奖励实际支付金额的1%</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip8</div>
          <div class="vip_level_tb">奖励实际支付金额的2%</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip10</div>
          <div class="vip_level_tb">奖励实际支付金额的3%</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
<style scoped lang="scss">
$vip-color: #c37c00;
.page_box {
  padding: 0 0.22rem 1rem 0.22rem;
}
.vip_level_nav {
  margin: 0.4rem 0 0.1rem 0;
  padding: 0 0.3rem;
  line-height: 0.4rem;
  border: 1px solid $vip-color;
  color: $vip-color;
  border-radius: 1rem;
}
.vip_level_img {
  margin: 0.3rem 0;
}
.vip_level_dec {
  line-height: 0.4rem;
  font-size: 0.23rem;
}
.vip_level_table {
  margin-top: 0.3rem;
  width: 100%;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: #fde2b0;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  height: 0.9rem;
  color: $vip-color;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 0.6rem;
  font-size: 0.23rem;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>